
export const environment = {
  APP_ENV: 'live',
  APP_NAME: 'TresorPay',
  APP_ONPREMISE: 'true',
  API_SCHEME: 'https',
  API_DOMAIN: 'prod.tresorpay.bj',
  API_VERSION: 'v1',
  SENTRY_DSN: 'https://36b3078b0dfe4870b65efc17cc76c067@o121865.ingest.sentry.io/6117008',
  CYBERSOURCE_URL: 'https://secureacceptance.cybersource.com/silent/embedded/pay',
  STRIPE_PUBLIC_KEY: 'undefined',
  EVISA_ACCOUNT_ID: 'undefined',
  GUFFE_ACCOUNT_ID: 'undefined',
  COVID_ACCOUNT_ID: 'undefined',
  GEO_API_URL: 'https://geo.fedapay.com'
};
